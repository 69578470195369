<template>
  <div class="information accordion-info">
    <div class="page-title">
      {{ translate(pageTitle, pageTitle) }}
    </div>

    <section class="sections">
      <section
        class="information-section">
        <b-jumbotron
          class="contained">
          <template #header>{{ translate(`method-${method.methodId}-contentTitle`, method.contentTitle) }}</template>
          <template
            #lead>
            <div v-html="translate(`method-${method.methodId}-body`, method.body)"></div>
          </template>
        </b-jumbotron>

        <article
          class="information-article contained"
          :class="{'open' : openSection == sectionIndex}"
          v-for="(section, sectionIndex) in method.sections"
          :key="sectionIndex">
          <div
            class="information-article-header"
            @click="toggleAccordion(sectionIndex)">
            <div class="label">
              {{ translate(`methodSection-${section.methodSectionId}-contentTitle`, section.contentTitle) }}
            </div>

            <div class="article-header-button-wrapper">
              <b-button
                class="round"
                variant="outline-info">
                <font-awesome-icon
                  :icon="['fal', openSection == sectionIndex ? 'times' : 'plus']" />
              </b-button>
            </div>
          </div>

          <div
            class="accordion-content"
            v-show="openSection == `${sectionIndex}`">
            <div
              class="info-section"
              v-html="translate(`methodSection-${section.methodSectionId}-body`, section.body)">
            </div>
          </div>
        </article>
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MethodInformation',

  data: () => ({
    pageTitle: 'information',
    openSection: null,
  }),

  computed: {
    ...mapGetters('method', [
      'methodById',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),

    method() {
      return this.methodById(this.$route.params.methodId) ?? null;
    },
  },

  methods: {
    toggleAccordion(sectionIndex) {
      this.openSection = this.openSection == sectionIndex ? null : sectionIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.questions {
  margin: 1.5rem 0;
}
</style>
